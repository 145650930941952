import React from "react";

function Home() {
    return (



        <div className="home">
            <div className="container">
                <div className="align-items-center my-5 border rounded bg-light p-2">

                    <div>


                        <h1 className="font-weight-light">-CONTACT-</h1>

                        <div className="mt-3">


                            <button className="font-weight-bold border rounded-pill bg-light py-1 mb-3">contact@stonefromthesky.fr</button>
                        </div>






                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;